import { Modal } from '@mui/material'
import CloseIcon from 'assets/svg/close.svg'
import { FlexContainer } from 'components'
import type { ComponentProps } from 'react'

import { DeBridgeWidget } from './DeBridgeWidget'
import { useDebridgeModalStore } from './useDebridgeModalStore'
type DebridgeModalProperties = ComponentProps<'div'>

export const DebridgeModal = (_props: DebridgeModalProperties) => {
  const { opened, setOpened, debridgeToken } = useDebridgeModalStore()

  return (
    <Modal
      open={opened}
      onClose={() => setOpened(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          width: '34.75rem',
          maxHeight: '90vh',
          overflow: 'auto',
          borderRadius: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <FlexContainer
          additionalStyles={{
            justifyContent: 'space-between',
            padding: '0rem 1rem',
          }}
        >
          <h2>Get {debridgeToken}</h2>
          <img src={CloseIcon} alt="close" onClick={() => setOpened(false)} style={{ cursor: 'pointer' }} />
        </FlexContainer>
        <DeBridgeWidget debridgeToken={debridgeToken} />
      </div>
    </Modal>
  )
}
