import UsdcIcon from 'assets/icons/tokens/usdc.svg'
import XfiIcon from 'assets/icons/tokens/xfi.svg'
import { CommonLayout, FlexContainer } from 'components'
import { CardCentered } from 'components/Card'
import { useIsMobileDevice } from 'hooks/useIsMobileDevice'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useYandexMetrikaHit } from 'hooks/useYandexMetrika'
import { useDebridgeModalStore } from 'modules/debridge-widget/useDebridgeModalStore'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSwapToolsStore } from 'stores/useSwapStore'
import styled from 'styled-components'

import GearIcon from '../../assets/svg/gear.svg'
import QuestionIcon from '../../assets/svg/question.svg'
import { FaucetModal } from './FaucetModal'
import SwapBlock from './SwapBlock'
import { Tools } from './Tools'
import { Icon, RoundedButton } from './Tools/styles'

const PageWrapper = styled.div`
  flex: 1;
  justify-content: center;
  margin: auto;
  width: 100%;

  display: flex;
`

const Card = styled(CardCentered)`
  width: 100%;
  gap: 1.5rem;
  padding: 2rem 1.5rem;

  ${({ theme }) => theme.mediaWidth.upToTablet`
    padding: 1.5rem 1rem;
  `}
`

const Title = styled.h2`
  font-size: 1.875rem;
  font-family: 'Helvetica Neue', sans-serif;

  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 2.25rem */
  letter-spacing: -0.01875rem;
  margin: 0;

  ${({ theme }) => theme.mediaWidth.upToTablet`
  font-size: 1.5rem;
  letter-spacing: -0.015rem;
  `}
`

const Main = styled.div`
  width: 34.75rem;
  position: relative;
  margin-top: 6.5rem;
  ${({ theme }) =>
    theme.mediaWidth.upToTablet`
    width: 100%;
    transition: all ease-in-out 300ms;
  `}
`

export default function Swap() {
  useYandexMetrikaHit()

  const { t } = useTranslation()
  const [pendingTx, setPendingTx] = useState('')

  const mainRef = useRef<HTMLDivElement | null>(null)
  const [slippage, setSlippage] = useLocalStorage<number | undefined | string>('SWAP_SLIPPAGE', 0.3)

  const { setCurrentTool, setIsFaucetModalOpen } = useSwapToolsStore()
  const isMobile = useIsMobileDevice()
  useEffect(() => {
    if (!isMobile && mainRef.current) {
      mainRef.current.style.transform = 'translateX(0)'
    }
  }, [isMobile])

  const { setOpened, setDebridgeToken } = useDebridgeModalStore()

  return (
    <CommonLayout>
      <PageWrapper>
        <Main ref={mainRef}>
          <Card>
            {!pendingTx && (
              <FlexContainer>
                <Title>{t('Swap.swap')}</Title>
                {isMobile && (
                  <FlexContainer additionalStyles={{ justifyContent: 'flex-end', gap: '0.75rem' }}>
                    <RoundedButton
                      style={{ position: 'relative' }}
                      onClick={() => {
                        setOpened(true)
                        setDebridgeToken('XFI')
                      }}
                    >
                      <span
                        style={{
                          position: 'absolute',
                          bottom: '0.1rem',
                          right: '0.3rem',
                          fontSize: '1rem',
                          fontWeight: 700,
                        }}
                      >
                        +
                      </span>
                      <Icon src={XfiIcon} />
                    </RoundedButton>
                    <RoundedButton
                      onClick={() => {
                        setOpened(true)
                        setDebridgeToken('USDC')
                      }}
                      style={{ position: 'relative' }}
                    >
                      <span
                        style={{
                          position: 'absolute',
                          bottom: '0.1rem',
                          right: '0.3rem',
                          fontSize: '1rem',
                          fontWeight: 700,
                        }}
                      >
                        +
                      </span>
                      <Icon src={UsdcIcon} />
                    </RoundedButton>
                    <RoundedButton
                      onClick={() => {
                        if (mainRef.current) {
                          mainRef.current.style.transform = 'translateX(calc(-100% - 1rem))'
                        }
                        setCurrentTool('slippage')
                      }}
                    >
                      <Icon src={GearIcon} />
                    </RoundedButton>
                    <RoundedButton
                      onClick={() => {
                        if (mainRef.current) {
                          mainRef.current.style.transform = 'translateX(calc(-100% - 1rem))'
                        }
                        setCurrentTool('help')
                      }}
                    >
                      <Icon src={QuestionIcon} />
                    </RoundedButton>
                  </FlexContainer>
                )}
              </FlexContainer>
            )}
            <SwapBlock slippage={slippage} onPendingTxChange={setPendingTx} />
          </Card>
          <FaucetModal />
          {!pendingTx && <Tools slippage={slippage} onSlippageChange={setSlippage} mainRef={mainRef} />}
        </Main>
      </PageWrapper>
    </CommonLayout>
  )
}
