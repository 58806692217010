import { useSwapRouterAddress, useWXFIAddress } from 'constants/app-contracts'
import { ZERO_ADDRESS } from 'constants/misc'
import { useActiveWeb3React } from 'hooks/web3'
import { useCallback, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { Address, formatEther, isAddressEqual } from 'viem'

interface SwapExecuteParams {
  callData: `0x${string}`
  transferValue?: string | number
  tokenIn?: string
  tokenOut?: string
}

export enum SwapExecuteState {
  UNKNOWN = 'UNKNOWN',
  LOADING = 'LOADING',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

interface SwapExecuteResult {
  execute: (params: SwapExecuteParams) => Promise<void>
  state: SwapExecuteState
  error: Error | null
  hash?: string
  reset: () => void
}

export const useSwapExecute = (): SwapExecuteResult => {
  const routerAddress = useSwapRouterAddress()
  const { provider, account } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const [error, setError] = useState<Error | null>(null)
  const [state, setState] = useState<SwapExecuteState>(SwapExecuteState.UNKNOWN)
  const [hash, setHash] = useState<string>()
  const wxfiAddress = useWXFIAddress()

  const execute = useCallback(
    async ({ callData, transferValue, tokenIn, tokenOut }: SwapExecuteParams) => {
      if (!provider || !account) {
        throw new Error('Missing dependencies')
      }

      try {
        const network = await provider.getNetwork()
        const blockNumber = await provider.getBlockNumber()
        console.log('🌐 [SwapExecute] Provider connection details:', {
          chainId: network.chainId,
          name: network.name,
          ensAddress: network.ensAddress,
          currentBlock: blockNumber,
          connection: {
            url: provider.connection?.url || 'Unknown',
          },
        })

        console.log('🔄 [SwapExecute] Starting swap execution on local node...')
        console.log('👤 [SwapExecute] Account:', account)
        console.log(
          '💰 [SwapExecute] Transfer value:',
          transferValue ? formatEther(BigInt(transferValue.toString())) : '0',
          'ETH'
        )

        setState(SwapExecuteState.LOADING)
        setError(null)

        // Get signer
        const signer = provider.getSigner(account)

        console.log('🔍 [SwapExecute] Token analysis:', {
          tokenIn: tokenIn || 'undefined',
          tokenOut: tokenOut || 'undefined',
          wxfiAddress,
        })

        // Check if this is XFI/WXFI swap
        const isXfiToWxfi =
          isAddressEqual((tokenIn as Address) || (ZERO_ADDRESS as Address), ZERO_ADDRESS as Address) &&
          isAddressEqual(
            (tokenOut as Address) || (ZERO_ADDRESS as Address),
            (wxfiAddress as Address) || (ZERO_ADDRESS as Address)
          )
        const isWxfiToXfi =
          isAddressEqual(
            (tokenIn as Address) || (ZERO_ADDRESS as Address),
            (wxfiAddress as Address) || (ZERO_ADDRESS as Address)
          ) && isAddressEqual((tokenOut as Address) || (ZERO_ADDRESS as Address), ZERO_ADDRESS as Address)

        const isXfiWxfiSwap = isXfiToWxfi || isWxfiToXfi

        console.log('🔄 [SwapExecute] Swap type analysis:', {
          isXfiToWxfi,
          isWxfiToXfi,
          isXfiWxfiSwap,
          tokenInAddress: tokenIn?.toLowerCase(),
          tokenOutAddress: tokenOut?.toLowerCase(),
          wxfiAddress: wxfiAddress?.toLowerCase(),
          targetContract: isXfiWxfiSwap ? 'WXFI Contract' : 'Router Contract',
          targetAddress: isXfiWxfiSwap ? wxfiAddress : routerAddress,
        })

        // Prepare transaction
        const tx = {
          to: isXfiWxfiSwap ? wxfiAddress : routerAddress,
          data: callData,
          value: transferValue ? transferValue.toString() : '0',
        }

        console.log('📝 [SwapExecute] Transaction data:', {
          to: tx.to,
          value: tx.value,
          dataLength: tx.data.length,
          callData: callData.slice(0, 66) + '...', // Show only beginning of callData
          isXfiWxfiSwap,
        })

        // Send transaction
        console.log('📤 [SwapExecute] Sending transaction to local node...')
        const response = await signer.sendTransaction(tx)

        // Add transaction to the store
        addTransaction(response, {
          summary: 'Swap execution',
          type: 'swap',
        })

        setHash(response.hash)
        setState(SwapExecuteState.PENDING)
        console.log('📨 [SwapExecute] Transaction sent! Hash:', response.hash)
      } catch (err) {
        console.error('❌ [SwapExecute] Failed to execute swap:', err)
        setError(err instanceof Error ? err : new Error('Failed to execute swap'))
        setState(SwapExecuteState.ERROR)
        throw err
      }
    },
    [provider, account, addTransaction, wxfiAddress]
  )

  const reset = useCallback(() => {
    console.log('🔄 [SwapExecute] Resetting state')
    setError(null)
    setState(SwapExecuteState.UNKNOWN)
    setHash(undefined)
  }, [])

  return {
    execute,
    state,
    error,
    hash,
    reset,
  }
}
