import { Stack, useMediaQuery } from '@mui/material'
import { DropDown } from 'components'
import { IAppToken } from 'components/blocks/AmountInput/useAppCoins'
import { ScientificNumber } from 'components/blocks/ScientificNumber'
import { useTxEstimatedGasValue } from 'components/FormActionBtn/FormActionBtn'
import { LinkLikeButton } from 'components/MUI/Button'
import { ITxTemplateInfo } from 'components/TransactionInfo/TransactionInfo'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { useTokenAsset } from 'hooks/useTokenAsset'
import { useExchangeRate } from 'lib/hooks/useExchangeRate'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ZERO } from 'utils/isZero'

import { RouteModal } from './RouteModal'

const Row = styled.div<{ danger?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;

  color: ${({ danger, theme }) => (danger ? theme.red : theme.text100)};
  font-weight: ${({ danger }) => (danger ? 700 : 400)};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 0.875rem;
  `}
`

export const MAX_PRICE_IMPACT = 20

const InfoBlock = ({
  style,
  slippage,
  priceChange,
  txInfo,
  path,
  estimateTransferSeconds,
  fee,
}: {
  style?: React.CSSProperties
  slippage?: number | string
  priceChange: number
  txInfo?: ITxTemplateInfo
  path?: string[]
  estimateTransferSeconds?: number
  fee?: {
    amount: string
    symbol: string
    decimals: string
    amountInUsd: string
  }
}) => {
  const { t } = useTranslation()

  const [showRoute, setShowRoute] = useState(false)

  const { gasValue } = useTxEstimatedGasValue(txInfo)

  const feeAmount = useMemo(() => {
    if (!fee?.amount) return '0'
    const amount = BigNumber.from(fee.amount)
    // 1% fee temporary
    const increasedAmount = amount.add(amount.mul(1).div(100))
    return formatUnits(increasedAmount, +(fee?.decimals ?? '18'))
  }, [fee])

  const feeAmountInUsd = useMemo(() => {
    if (!fee?.amountInUsd) return 0
    // 1% fee temporary
    const amount = +fee.amountInUsd * 1.01
    return amount
  }, [fee])

  return (
    <>
      <Stack style={style} gap=".75rem">
        <Row danger={priceChange >= MAX_PRICE_IMPACT}>
          <span>{t('Swap.priceImpact')}</span>
          <span>-{priceChange.toFixed(2)}%</span>
        </Row>
        <Row>
          <span>{t('Swap.maxSlippage')}</span>
          <span>{slippage}%</span>
        </Row>
        <Row>
          <span>{t('Swap.gas')}</span>
          <span>{gasValue} XFI</span>
        </Row>
        {fee && (
          <Row>
            <span>{t('Swap.fee')}</span>
            <span>
              <ScientificNumber value={feeAmount} /> {fee.symbol} ($
              <ScientificNumber value={feeAmountInUsd} />)
            </span>
          </Row>
        )}
        {estimateTransferSeconds && (
          <Row>
            <span>{t('Swap.estimatedTime')}</span>
            <span>{estimateTransferSeconds}s</span>
          </Row>
        )}
      </Stack>

      {path?.length ? (
        <>
          <Row
            style={{
              marginTop: '1.5rem',
            }}
          >
            <span>{t('Swap.route')}</span>
            <LinkLikeButton
              onClick={(e) => {
                e.stopPropagation()
                setShowRoute(true)
              }}
            >
              {t('Swap.view')}
            </LinkLikeButton>
          </Row>

          <RouteModal
            hideCost={!gasValue}
            cost={`${gasValue} XFI`}
            isOpenFlag={showRoute}
            onClose={() => {
              setShowRoute(false)
            }}
          />
        </>
      ) : null}
    </>
  )
}

export const AdditionalInfo = ({
  symbolFirst,
  symbolSecond,
  expectedAmountOut = ZERO,
  slippage,
  priceChange,
  txInfo,
  path,
  decimalsOut,
  estimateTransferSeconds,
  fee,
  amountIn,
  decimalsIn,
}: {
  symbolFirst: string
  symbolSecond: string
  expectedAmountOut: BigNumber
  slippage?: number | string
  priceChange: number
  txInfo?: ITxTemplateInfo
  path?: string[]
  decimalsOut?: number
  estimateTransferSeconds?: number
  fee?: {
    amount: string
    symbol: string
    decimals: string
    amountInUsd: string
  }
  amountIn: BigNumber
  decimalsIn: number
}) => {
  const exchangeRate = useExchangeRate(amountIn, expectedAmountOut, decimalsIn, decimalsOut || 18)
  const firstSymbol = symbolFirst || '...'
  const secondSymbol = symbolSecond || '...'

  const { t } = useTranslation()

  const title = amountIn.isZero() ? (
    t('Swap.details')
  ) : (
    <span>
      1 {firstSymbol} = <ScientificNumber value={exchangeRate} /> {secondSymbol}
    </span>
  )

  return (
    <DropDown title={title}>
      <InfoBlock
        slippage={slippage}
        priceChange={priceChange}
        style={{
          marginTop: '1.5rem',
        }}
        txInfo={txInfo}
        path={path}
        estimateTransferSeconds={estimateTransferSeconds}
        fee={fee}
      />
    </DropDown>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 1.25rem 1rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.divStroke};
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eff2f4;

  color: ${({ theme }) => theme.text60};
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 0.875rem;
  `}
`
const TokensSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`

const Token = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: ${({ theme }) => theme.text100};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 0.875rem;
  `}
`

const TokenLogo = styled.img`
  width: 1.5rem;
  height: 1.5rem;

  ${({ theme }) => theme.mediaWidth.upToTablet`
    width: 1.25rem;
    height: 1.25rem;
  `}
`

export const TotalInfo = ({
  assetIn,
  assetOut,
  ammountIn,
  ammountOut,
  pointsAmount,
  slippage,
  priceChange,
  txInfo,
  path,
}: {
  assetIn?: IAppToken
  assetOut?: IAppToken
  ammountIn?: BigNumber
  ammountOut?: BigNumber
  pointsAmount?: BigNumber
  priceChange: number
  slippage?: number | string
  txInfo?: ITxTemplateInfo
  path?: string[]
}) => {
  const tokenData0 = useTokenAsset(assetIn?.address)
  const tokenData1 = useTokenAsset(assetOut?.address)

  const { t } = useTranslation()

  const isMobile = useMediaQuery('(max-width: 1025px)')

  return (
    <Container>
      <Header>
        <TokensSection>
          <Token>
            <TokenLogo src={tokenData0?.icon} alt={tokenData0?.symbol} />
            {ammountIn && <ScientificNumber value={formatUnits(ammountIn, assetIn?.decimals ?? 18)} />}
            {!isMobile && <span>{tokenData0?.symbol?.toUpperCase()}</span>}
          </Token>
          <span> to </span>
          <Token>
            <TokenLogo src={tokenData1?.icon} alt={tokenData1?.symbol} />
            {ammountOut && <ScientificNumber value={formatUnits(ammountOut, assetOut?.decimals ?? 18)} />}
            {!isMobile && <span>{tokenData1?.symbol?.toUpperCase()}</span>}
          </Token>
        </TokensSection>

        {/* TODO расскомментить когда вернем поинты */}
        {/* {pointsAmount && (
          <span>
            + {formatDecimal(pointsAmount)} {t('Swap.points')}
          </span>
        )} */}
      </Header>

      <InfoBlock
        path={path}
        slippage={slippage || 0}
        priceChange={priceChange}
        txInfo={txInfo}
        style={{ marginTop: '1rem' }}
      />
    </Container>
  )
}
