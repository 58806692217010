const widgetStyles = {
  appBackground: '#ffffff',
  modalBg: '#ffffff',
  chartBg: '#ffffff',
  borderRadius: 25,
  borderColor: '#e7e9f3',
  tooltipBg: '#ffffff',
  tooltipColor: '#000000',
  formControlBg: '#ffffff',
  controlBorder: '#e7e9f3',
  primary: '#ff7236',
  secondary: '#3a66c1',
  success: '#59d894',
  error: '#ff4949',
  fontColor: '#000000',
  fontFamily: 'Manrope',
  primaryBtnBg: '#46464a',
  primaryBtnBgHover: '#646676',
  primaryBtnText: '#ffffff',
  secondaryBtnBg: '#f9f9f9',
  secondaryBtnOutline: '#e7e9f3',
  btnPadding: {
    top: 16,
    right: null,
    bottom: 16,
    left: null,
  },
  btnFontSize: 16,
  btnFontWeight: 400,
  formHeadBtnSize: '40',
}
const encodedStyles = btoa(JSON.stringify(widgetStyles))

export const DEBRIDGE_WIDGET_CONFIG = {
  v: '1',
  element: 'debridgeWidget',
  title: '',
  description: '',
  isAutoHeight: true,
  height: '640',
  isAutoWidth: true,
  affiliateFeePercent: '1',
  affiliateFeeRecipient: '0x6A02E7A844a012cEa11A0Ed873C26EFa5b92142C',
  width: '100%',
  r: '31521',
  supportedChains: {
    inputChains: {
      '1': 'all',
      '10': 'all',
      '56': 'all',
      '100': 'all',
      '137': 'all',
      '146': 'all',
      '250': 'all',
      '388': 'all',
      '998': 'all',
      '1088': 'all',
      '2741': 'all',
      '4158': 'all',
      '7171': 'all',
      '8453': 'all',
      '42161': 'all',
      '43114': 'all',
      '59144': 'all',
      '80094': 'all',
      '7565164': 'all',
      '245022934': 'all',
    },
    outputChains: {
      '1': 'all',
      '10': 'all',
      '56': 'all',
      '100': 'all',
      '137': 'all',
      '146': 'all',
      '250': 'all',
      '388': 'all',
      '998': 'all',
      '1088': 'all',
      '2741': 'all',
      '4158': 'all',
      '7171': 'all',
      '8453': 'all',
      '42161': 'all',
      '43114': 'all',
      '59144': 'all',
      '80094': 'all',
      '7565164': 'all',
      '245022934': 'all',
    },
  },
  inputChain: 1,
  outputChain: 4158,
  inputCurrency: '',
  outputCurrency: '',
  address: '',
  showSwapTransfer: true,
  amount: '',
  outputAmount: '',
  isAmountFromNotModifiable: false,
  isAmountToNotModifiable: false,
  lang: 'en',
  mode: 'deswap',
  isEnableCalldata: false,
  styles: encodedStyles,
  theme: 'dark',
  isHideLogo: true,
  logo: '',
  disabledWallets: [],
  disabledElements: [],
}
