import { create } from 'zustand'

interface UseDebridgeModalStoreState {
  opened: boolean
  setOpened: (opened: boolean) => void
  debridgeToken: 'USDC' | 'XFI'
  setDebridgeToken: (debridgeToken: 'USDC' | 'XFI') => void
}

export const useDebridgeModalStore = create<UseDebridgeModalStoreState>()((set) => ({
  opened: false,
  setOpened: (opened) => set({ opened }),
  debridgeToken: 'USDC',
  setDebridgeToken: (debridgeToken) => set({ debridgeToken }),
}))
