/* eslint-disable import/no-unused-modules */
import { TokenSymbol } from 'components/blocks/AmountInput/TokenSymbol'
import {
  ARBITRUM_USDT as ArbitrumUsdtAddress,
  BASE_USDT as BaseUsdtAddress,
  BSC_USDT as BscUsdtAddress,
  ETH_USDT as EthUsdtAddress,
  LP_ADDRESS,
  POLYGON_USDT as PolygonUsdtAddress,
  TRON_USDT as TronUsdtAddress,
  USDT_TESTNET,
  XUSD_MAINNET,
  XUSD_TESTNET,
} from 'constants/app-contracts'
import { useMemo } from 'react'
import { isAddress } from 'utils'

import bnbIcon from '../assets/icons/tokens/bnb.svg'
import esXFI from '../assets/icons/tokens/esXfi.svg'
import ETHIcon from '../assets/icons/tokens/eth.svg'
import fomoIcon from '../assets/icons/tokens/fomo.png'
import lpXFI from '../assets/icons/tokens/lp.svg'
import solIcon from '../assets/icons/tokens/sol.svg'
import usdcIcon from '../assets/icons/tokens/usdc.svg'
import usdtIcon from '../assets/icons/tokens/usdt.svg'
import WBTCIcon from '../assets/icons/tokens/wbtc.png'
import WETHIcon from '../assets/icons/tokens/weth.png'
import xfiIcon from '../assets/icons/tokens/xfi.svg'
import xUsdIcon from '../assets/icons/tokens/xUsd.svg'
import arbitrumSvg from '../assets/svg/small/arbitrum.svg'
import baseSvg from '../assets/svg/small/base.svg'
import bscSvg from '../assets/svg/small/bsc.svg'
import ethereumIcon from '../assets/svg/small/ethereum.svg'
import polygonSvg from '../assets/svg/small/polygon.svg'
import tronSvg from '../assets/svg/small/tron.svg'

export interface ITokenAsset {
  icon?: string
  symbol: TokenSymbol
  name?: string
  address?: string
  chainIcon?: string
  decimals?: number
}

const XFI_USDT: ITokenAsset = {
  icon: usdtIcon,
  symbol: TokenSymbol.usdt,
  name: 'Tether USD (XFI)',
  address: USDT_TESTNET,
  chainIcon: xfiIcon,
}

const TRON_USDT: ITokenAsset = {
  icon: usdtIcon,
  symbol: TokenSymbol.usdt,
  name: 'TRON',
  address: TronUsdtAddress,
  chainIcon: tronSvg,
}

const BASE_USDT: ITokenAsset = {
  icon: usdtIcon,
  symbol: TokenSymbol.usdt,
  name: 'BASE',
  address: BaseUsdtAddress,
  chainIcon: baseSvg,
}

const POLYGON_USDT: ITokenAsset = {
  icon: usdtIcon,
  symbol: TokenSymbol.usdt,
  name: 'POLYGON',
  address: PolygonUsdtAddress,
  chainIcon: polygonSvg,
}

const ARBITRUM_USDT: ITokenAsset = {
  icon: usdtIcon,
  symbol: TokenSymbol.usdt,
  name: 'ARBITRUM',
  address: ArbitrumUsdtAddress,
  chainIcon: arbitrumSvg,
}

const ETH_USDT: ITokenAsset = {
  icon: usdtIcon,
  symbol: TokenSymbol.usdt,
  name: 'ETH',
  address: EthUsdtAddress,
  chainIcon: ethereumIcon,
}

const BSC_USDT: ITokenAsset = {
  icon: usdtIcon,
  symbol: TokenSymbol.usdt,
  name: 'BSC',
  address: BscUsdtAddress,
  chainIcon: bscSvg,
}

export const TOKENS: ITokenAsset[] = [
  {
    icon: lpXFI,
    symbol: TokenSymbol.lpXFI,
    name: 'XFI LP',
    address: LP_ADDRESS,
    chainIcon: xfiIcon,
  },
  {
    icon: esXFI,
    symbol: TokenSymbol.esXFI,
    name: 'esXFI',
    address: '',
    chainIcon: xfiIcon,
  },
  {
    icon: xfiIcon,
    symbol: TokenSymbol.xfi,
    name: 'CrossFi Token',
    address: '0x0000000000000000000000000000000000000000',
    chainIcon: xfiIcon,
    decimals: 18,
  },
  {
    icon: ETHIcon,
    symbol: TokenSymbol.eth,
    name: 'Ethereum',
    address: '',
    chainIcon: xfiIcon,
  },
  {
    icon: xfiIcon,
    symbol: TokenSymbol.wxfi,
    name: 'Wrapped XFI',
    address: '0xC537D12bd626B135B251cCa43283EFF69eC109c4',
    chainIcon: xfiIcon,
    decimals: 18,
  },
  {
    icon: WETHIcon,
    symbol: TokenSymbol.weth,
    name: 'Wrapped Ethereum',
    address: '0xa084d905e3F35C6B86B5E672C2e72b0472ddA1e3',
    chainIcon: xfiIcon,
  },
  {
    icon: WBTCIcon,
    symbol: TokenSymbol.wbtc,
    name: 'Wrapped Bitcoin',
    address: '0x417c85B9D0826501d7399FEeF417656774d333cc',
    chainIcon: xfiIcon,
    decimals: 8,
  },
  {
    icon: fomoIcon,
    symbol: TokenSymbol.fomo,
    name: 'Fear of Missing Out',
    address: '0x608A092CDa76620C06bD9d75b1D1719cdC36600f',
    chainIcon: xfiIcon,
    decimals: 18,
  },
  {
    icon: usdcIcon,
    symbol: TokenSymbol.usdc,
    name: 'USD Coin',
    address: '0x7bBcE15166bBc008EC1aDF9b3D6bbA0602FCE7Ba',
    chainIcon: xfiIcon,
    decimals: 6,
  },
  {
    icon: usdtIcon,
    symbol: TokenSymbol.usdt,
    name: 'Tether USD',
    address: '0x38E88b1ed92065eD20241A257ef3713A131C9155',
    chainIcon: xfiIcon,
    decimals: 6,
  },
  {
    icon: xUsdIcon,
    symbol: TokenSymbol.xUsd,
    name: 'XUSD',
    address: XUSD_TESTNET,
    chainIcon: xfiIcon,
  },
  {
    icon: xUsdIcon,
    symbol: TokenSymbol.xUsd,
    name: 'XUSD',
    address: XUSD_MAINNET,
    chainIcon: xfiIcon,
  },
  {
    icon: bnbIcon,
    symbol: TokenSymbol.bnb,
    name: 'BNB',
    address: '0x40F6226bB42E440655D5741Eb62eE95d0159F344',
    chainIcon: xfiIcon,
    decimals: 18,
  },
  {
    icon: solIcon,
    symbol: TokenSymbol.sol,
    name: 'SOL',
    address: '0x5b9bec66bB3d1559Fc6E05bfCAE7a1b5cdf678BE',
    chainIcon: xfiIcon,
    decimals: 9,
  },
]

// by symbol
// by token address
export const useTokenAsset = (query?: string) => {
  const queryLower = query?.toLowerCase()

  const foundToken = useMemo(() => {
    if (!queryLower) return undefined

    let token
    if (isAddress(queryLower)) {
      token = TOKENS.find((token) => {
        return token.address?.toLowerCase() === queryLower
      })
    } else {
      token = TOKENS.find((token) => token.symbol?.toLowerCase() === queryLower)
    }

    return token
  }, [queryLower])

  return foundToken
}
