import { useQuery } from '@tanstack/react-query'
import { API_URL } from 'api/api'
import { useMemo } from 'react'

import { AddLiquidityInfoResponse, AddLiquidityInfoResult } from '../types/api.types'

const DEBUG_TAG = 'USE_ADD_LIQUIDITY_INFO'

const fetchAddLiquidityInfo = async (url: string): Promise<AddLiquidityInfoResponse> => {
  if (!url) {
    throw new Error('Invalid parameters provided')
  }

  console.log(`[${DEBUG_TAG}] Fetching add liquidity info from:`, url)
  const response = await fetch(url)
  const data = await response.json()

  console.log(`[${DEBUG_TAG}] API Response:`, {
    data: data.data ? `${data.data.slice(0, 10)}...` : undefined,
    transferAddress: data.transferAddress,
    transferValue: data.transferValue,
    approvalAddress: data.approvalAddress,
    estimateTransferSeconds: data.estimateTransferSeconds,
    hopsCount: data.hopsCount,
  })

  if ('detail' in data) {
    const error = Array.isArray(data.detail) ? data.detail[0].msg : data.detail
    console.error(`[${DEBUG_TAG}] API Error:`, error)
    throw new Error(error)
  }

  return data
}

interface UseAddLiquidityInfoParams {
  /** Address of the first token */
  token0: string
  /** Address of the second token */
  token1: string
  /** Address of the user */
  user: string
  /** Amount of token0 */
  amount0: string
  /** Amount of token1 */
  amount1: string
  /** Slippage percentage. Default: 0.01 */
  slippage?: number
}

/**
 * Hook for fetching add liquidity execution information from API using react-query
 */
export const useAddLiquidityInfo = ({
  token0,
  token1,
  user,
  amount0,
  amount1,
  slippage = 0.005,
}: UseAddLiquidityInfoParams): {
  result?: AddLiquidityInfoResult
  pending: boolean
} => {
  const url = useMemo(() => {
    if (!token0 || !token1 || !user || !amount0 || !amount1) {
      return ''
    }

    const params = new URLSearchParams({
      token0,
      token1,
      user,
      amount0: amount0.toString(),
      amount1: amount1.toString(),
      slippage: slippage.toString(),
    })

    return `${API_URL}liquidity/execute?${params.toString()}`
  }, [token0, token1, user, amount0, amount1, slippage])

  const { data, isLoading, error } = useQuery({
    queryKey: ['addLiquidityInfo', token0, token1, user, amount0, amount1, slippage],
    queryFn: () => fetchAddLiquidityInfo(url),
    enabled: !!url && +amount0 > 0 && +amount1 > 0,
    staleTime: 30000, // Consider data stale after 30 seconds
    gcTime: 60000, // Keep data in cache for 1 minute
  })

  const result = useMemo((): AddLiquidityInfoResult | undefined => {
    if (error) {
      return {
        error: error instanceof Error ? error.message : 'Unknown error',
      }
    }

    if (!data) return undefined

    return {
      data: data.data,
      transferAddress: data.transferAddress,
      transferValue: data.transferValue?.toString(),
      approvalAddress: data.approvalAddress,
      estimateTransferSeconds: data.estimateTransferSeconds,
      hopsCount: data.hopsCount,
    }
  }, [data, error])

  return {
    result,
    pending: isLoading,
  }
}
