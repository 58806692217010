import type { ComponentProps } from 'react'
import { useEffect, useRef } from 'react'

import { DEBRIDGE_WIDGET_CONFIG } from './config'

declare global {
  interface Window {
    deBridge: {
      widget: (config: any) => void
    }
  }
}

interface DeBridgeWidgetProperties extends ComponentProps<'div'> {
  debridgeToken: 'USDC' | 'XFI'
}

export const DeBridgeWidget = (props: DeBridgeWidgetProperties) => {
  const { debridgeToken } = props
  const scriptReference = useRef<HTMLScriptElement | null>(null)

  useEffect(() => {
    scriptReference.current = document.createElement('script')
    scriptReference.current.src = 'https://app.debridge.finance/assets/scripts/widget.js'
    scriptReference.current.async = true

    scriptReference.current.addEventListener('load', () => {
      window.deBridge?.widget({
        ...DEBRIDGE_WIDGET_CONFIG,
        outputCurrency: debridgeToken === 'USDC' ? '0x7bbce15166bbc008ec1adf9b3d6bba0602fce7ba' : '',
      })
    })

    document.body.append(scriptReference.current)

    return () => {
      if (scriptReference.current && document.body.contains(scriptReference.current)) {
        scriptReference.current.remove()
        scriptReference.current = null
      }
    }
  }, [])

  return <div id="debridgeWidget" />
}
