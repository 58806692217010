export const Paths = {
  SWAP: '/swap',
  NOT_FOUND: '/not-found',
  DEFAULT: '/',
  POOLS: '/pools',
  FARM: '/farm',
  POINTS: '/points',
  BOND: 'https://bond.xswap.ms/bonds',
  DOCS: 'https://docs.xswap.ms/',
  DOCS_VI: 'https://docs.xswap.ms/v/xswap-vn',
}
