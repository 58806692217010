import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'

export const useExchangeRate = (amountIn: BigNumber, amountOut: BigNumber, decimalsIn: number, decimalsOut: number) => {
  if (amountIn.isZero() || amountOut.isZero()) return '0'

  const rate = amountOut.mul(BigNumber.from(10).pow(decimalsIn)).div(amountIn)

  return formatUnits(rate.toString(), decimalsOut)
}
